










































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import CourseGroupMonthChainMixin from '@/mixins/CourseGroupMonthChainMixin'
import DictionaryModule from '@/store/modules/dictionary'
import { SmartSearchFilter } from '@/store/types'

@Component({
  components: {
    CourseSearch,
    Select,
    ValidationProvider,
  },
})
export default class SmartSearchFieldsRow extends Mixins(CourseGroupMonthChainMixin) {
  @Prop({ required: true })
  private filter!: SmartSearchFilter

  @Prop({ default: null })
  private protectedField!: number

  private get orderStatusOptions() {
    return DictionaryModule.orderStatuses
  }

  private onCourseChange () {
    delete this.filter.groupId
    delete this.filter.monthIds
  }

  private onGroupTypeChange (value: number | null) {
    if (value === null) {
      delete this.filter.groupId
    }
    delete this.filter.monthIds
  }
}
