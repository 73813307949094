import { Component, Vue } from 'vue-property-decorator'

import { NameValueChildrenResource, NameValueChildrenTypeResource, NameValueResource } from '@/store/types'
import { formatDate } from '@/utils/functions'

@Component
export default class CourseGroupMonthChainMixin extends Vue {
  protected groupTypes: Array<NameValueChildrenResource | NameValueChildrenTypeResource> = []
  protected months: Array<NameValueChildrenResource | NameValueResource> = []

  protected handleChangeCourse (course: NameValueChildrenResource, callback: any) {
    if (course) {
      this.groupTypes = course.children
      this.months = this.groupTypes[0].children ? this.groupTypes[0].children.map((item: NameValueChildrenResource | NameValueResource) => ({ ...item, name: formatDate(item.name, 'yyyy LLLL') })) : []

      if (typeof callback === 'function') {
        callback(course)
      }
    } else {
      this.groupTypes = []
      this.months = []

      if (typeof callback === 'function') {
        callback()
      }
    }
  }

  protected handleChangeGroupType (value: number, callback: any) {
    if (!value) {
      if (typeof callback === 'function') {
        callback(value)
      }
    }
  }
}
