






































































































import { isEqual } from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// interfaces
import {
  ITableOptions,
  OrderStatus,
  SearchOperator,
  SearchType,
  SmartSearchFilter,
  SmartSearchRequest,
  UserManagerResource,
} from '@/store/types'
// components
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import DictionaryModule from '@/store/modules/dictionary'
import SmartSearchFields from '@/components/forms/smart-search/SmartSearchFields.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
// utils
import { tableFooterOptions } from '@/utils/constants'
// store
import ManagerUsersModule from '@/store/modules/manager/users'
import { GET_DEFAULT_PAGINATOR } from '@/store'
import { openNewTab } from '@/utils/functions'

@Component({
  components: {
    CourseSearch,
    Select,
    SmartSearchFields,
    TableFooter,
    Tag,
    TextInput,
    UserSocialCard,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class SmartSearchModal extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ default: SearchType.BY_USER_INFO })
  private searchType!: SearchType

  @Prop({ default: false })
  private noSwitchMethodSearch!: boolean

  // Если при нажатии на кнопку закрытия нужно редиректить пользователя куда-то, а не закрывать модалку (если ему недоступна страница просмотра пользователей)
  @Prop({ required: false })
  private redirectCloseButton!: boolean

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private footerOptions = tableFooterOptions

  private selectedMasters: UserManagerResource[] = []
  private searchData: SmartSearchRequest = Object.assign({ type: this.searchType })

  private get filter () {
    return ManagerUsersModule.smartSearchFilter
  }

  private set filter (filter: ITableOptions) {
    if (!isEqual(filter, this.filter)) {
      ManagerUsersModule.setSmartSearchFilter(filter)
      this.handleSearch()
    }
  }

  private get users () {
    return ManagerUsersModule.smartSearchUsers.data
  }

  private get pagination () {
    return ManagerUsersModule.smartSearchUsers.meta
  }

  private get headers () {
    return [
      {
        sortable: false,
        text: '#',
        value: 'id',
      },
      {
        sortable: false,
        text: 'Пользователь',
        value: 'person',
      },
      {
        sortable: false,
        text: 'Почта',
        value: 'email',
      },
      {
        sortable: false,
        text: 'Телефон',
        value: 'phone',
      },
      {
        sortable: false,
        text: 'Город',
        value: 'city',
      },
      {
        sortable: false,
        text: 'Статус',
        value: 'status',
      },
    ]
  }

  private formatCity (cityName: string) {
    return cityName.slice(3)
  }

  private formatPhone (phone: string) {
    if (phone.length === 0) {
      return ''
    }
    return '+7' + ' ' + phone.substring(1, 4) + ' ' +
      phone.substring(4, 7) + ' ' +
      phone.substring(7, 9) + ' ' +
      phone.substring(9, 11)
  }

  private get isOrderSearch () {
    return this.searchData.type === SearchType.BY_ORDERS
  }

  private get searchTypes () {
    return DictionaryModule.searchTypes
  }

  private get searchOperators () {
    return DictionaryModule.searchOperators
  }

  private get initialFilter (): SmartSearchFilter {
    return { status: OrderStatus.PAID }
  }

  private mounted () {
    if (this.isOrderSearch) {
      this.searchData.operator = SearchOperator.AND
      this.searchData.filters = [this.initialFilter]
    }
  }

  private destroyed() {
    ManagerUsersModule.setSmartSearchUsers(GET_DEFAULT_PAGINATOR())
    if (this.isOrderSearch) {
      this.searchData.operator = SearchOperator.AND
      this.searchData.filters = [this.initialFilter]
    }
  }

  private handleExport () {
    const filter = {
      search: { ...this.searchData },
      ...this.filter,
    }
    openNewTab('/manager/users/export', filter)
  }

  private handleAddField () {
    if (this.searchData.filters) {
      this.searchData.filters.push({ status: OrderStatus.PAID })
    }
  }

  private resetUsers () {
    ManagerUsersModule.setSmartSearchUsers(GET_DEFAULT_PAGINATOR())
  }

  private handleRowClick (user: UserManagerResource) {
    this.$emit('selectUser', user)
    this.$emit('update:visible', false)
  }

  private handleSearch (resetPage = false) {
    const form: any = this.$refs.searchModal
    if (form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.searchData.tgUsername = this.normalizeTelegramLogin(this.searchData.tgUsername)
            ManagerUsersModule.fetchSmartSearchUsers({ ...this.searchData, resetPage })
              .then(() => {
                requestAnimationFrame(() => (form.reset()))
              })
              .catch(this.notifyError)
          } else {
            this.notifyError('Проверьте введенные данные')
          }
        })
    }

  }

  private handleClose () {
    if (this.redirectCloseButton) {
      this.$emit('redirect')
    } else {
      this.$emit('update:visible', false)
      this.$emit('close', false)
    }
  }

  private normalizeTelegramLogin(login: string | undefined) {
    if(login?.includes('@')) {
      login = login.slice(1)
      return login
    }

    if(login?.includes('https://t.me/')) {
      login = login.slice(13)
      return login
    }

    return login
  }

  private handleSearchClick() {
    this.searchData.tgUsername = this.normalizeTelegramLogin(this.searchData.tgUsername)
    this.handleSearch(true)
  }

  @Watch('isOrderSearch')
  watchChangingSearch (value: boolean) {
    this.resetUsers()
    if (value) {
      this.searchData = { type: SearchType.BY_ORDERS }
      this.searchData.operator = SearchOperator.AND
      this.searchData.filters = [this.initialFilter]
    } else {
      this.searchData = { type: SearchType.BY_USER_INFO }
    }
  }
}
