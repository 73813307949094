


















import { Component, Prop, Vue } from 'vue-property-decorator'

interface IList {
  disabled?: boolean,
  icon?: string,
  iconColor?: string,
  name: string,
  value: string,
}

@Component({
  inheritAttrs: false,
})
export default class Parameters extends Vue {
  @Prop({ default: 'Параметры' })
  private title!: string

  @Prop({ default: '$arrowDown' })
  private iconName!: string

  @Prop({ default: 'teal darken-4' })
  private skin!: 'secondary' | 'teal darken-4'

  @Prop({ default: () => ([]) })
  private list!: IList[]

  @Prop({ default: true })
  private buttonText!: boolean

  @Prop({ default: false })
  private isAfterIcon!: boolean

  private handleOptionClick (item: IList) {
    this.$emit('select-option', item.value)
  }
}
