




































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import Parameters from '@/components/_uikit/Parameters.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SmartSearchModal from '@/components/modals/users/SmartSearchModal.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerUsersModule from '@/store/modules/manager/users'
import {
  IUsersFilter,
  IUsersFilterType,
  UserManagerResource,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import { openNewTab } from '@/utils/functions'

enum MenuActions {
  EXCEL = 'excel',
  SMART_SEARCH = 'smartSearch',
}

@Component({
  components: {
    Parameters,
    Select,
    SmartSearchModal,
    TableFooter,
    Tag,
    TextInput,
    UserSocialCard,
  },
})
export default class EmployeesTable extends Mixins(NotifyMixin, PermissionsMixin) {
  private footerOptions = tableFooterOptions
  private showSmartSearchMasters = false

  private get headers () {
    return [
      {
        sortable: false,
        text: 'Сотрудник',
        value: 'user',
      },
      {
        sortable: false,
        text: 'Почта',
        value: 'email',
      },
      {
        sortable: false,
        text: 'Телефон',
        value: 'phone',
      },
      {
        sortable: false,
        text: 'Город',
        value: 'city',
      },
      {
        sortable: false,
        text: 'Статус',
        value: 'status',
      },
    ]
  }

  private get filter () {
    return ManagerUsersModule.usersFilter
  }

  private set filter (filter: IUsersFilter) {
    if (!isEqual(filter, this.filter) || this.users.length === 0) {
      ManagerUsersModule.setUsersFilter(filter)
      this.fetchMentors()
    }
  }

  private get menu () {
    return [
      {
        name: 'Умный поиск',
        value: MenuActions.SMART_SEARCH,
      },
      {
        name: 'Выгрузить в excel',
        value: MenuActions.EXCEL,
      },
    ]
  }

  private get roles () {
    return DictionaryModule.roles
  }

  private get statuses () {
    return DictionaryModule.userStatuses
  }

  private get users () {
    return ManagerUsersModule.users.data
  }

  private get pagination () {
    return ManagerUsersModule.users.meta
  }

  private get onlySmartSearchAvailable () {
    return this.hasPermission(this.Permission.USERS_MASTER_SEARCH) && !this.hasPermission(this.Permission.USERS_MASTER_LIST)
  }

  private mounted () {
    if (this.onlySmartSearchAvailable) {
      this.showSmartSearchMasters = true
    }
  }

  private formatCity (cityName: string) {
    return cityName.slice(3)
  }

  private formatPhone (phone: string) {
    if (phone.length === 0) {
      return ''
    }
    return '+7' + ' ' + phone.substring(1, 4) + ' ' +
      phone.substring(4, 7) + ' ' +
      phone.substring(7, 9) + ' ' +
      phone.substring(9, 11)
  }

  @Bind
  @Debounce(300)
  private fetchMentors () {
    if (!this.onlySmartSearchAvailable) {
      ManagerUsersModule.fetchUsers()
        .catch(this.notifyError)
    }
  }

  private handleFilter (field: string, value: IUsersFilterType) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleExport () {
    openNewTab('/manager/users/export', this.filter)
  }

  private handleMenuItemClick (action: string) {
    switch (action) {
    case MenuActions.EXCEL:
      this.handleExport()
      break
    case MenuActions.SMART_SEARCH:
      this.showSmartSearchMasters = true
      break
    }
  }

  private handleRedirectEmployee (employee: UserManagerResource) {
    if (this.hasPermission(this.Permission.USERS_VIEW)) {
      this.$router.push({
        name: 'manager.users.employees.item',
        params: { employeeUUID: employee.uuid },
      })
    }
  }
}
