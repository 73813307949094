
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// modules
import ProfileModule from '@/store/modules/profile'
// interfaces
import { SocialResource, SocialType, UserShortResource } from '@/store/types'

@Component
export default class UserSocialCard extends Vue {
  @Prop({ default: () => ({}) })
  private user!: UserShortResource

  @Prop({ default: '' })
  private userName!: string

  @Prop({ default: '' })
  private hint!: string

  @Prop({ default: SocialType.VKONTAKTE })
  private socialMediaName!: string

  // Вручную показать, что соцка привязана. Нужно, если передается не UserShortResource
  @Prop({ default: false })
  private linked!: boolean

  @Prop({ default: '' })
  private redirectOnNameTo!: string

  @Prop({ default: false })
  private emphasis!: boolean

  // Нужны изменения на бэке
  private get userSocials() {
    return (this.user.socials?.length ? this.user.socials : []) as SocialResource[]
  }

  private get requiredSocials() {
    return ProfileModule.requiredSocials
  }

  private socialIsLinked(socialType: string) {
    const normalizeSocials = this.userSocials.map(item => item.type)
    return !!normalizeSocials.filter(item => item === socialType).length
  }

}
