








import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

import EmployeesTable from '@/components/tables/EmployeesTable.vue'

@Component({
  components: { EmployeesTable },
})
export default class EmployeesList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Сотрудники',
    }
  }
}
