var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.onlySmartSearchAvailable)?_c('div',{staticClass:"filter space-b-6"},[_c('Select',{staticClass:"filter__col-3",attrs:{"options":_vm.roles,"label":"Группа прав","placeholder":"Выберите группу","dense":"","outlined":"","rounded":"","clearable":""},on:{"input":function (value) { return _vm.handleFilter('roleId', value); }},model:{value:(_vm.filter.roleId),callback:function ($$v) {_vm.$set(_vm.filter, "roleId", $$v)},expression:"filter.roleId"}}),_c('Select',{staticClass:"filter__col-3",attrs:{"options":_vm.statuses,"label":"Статус","placeholder":"Выберите статус","dense":"","outlined":"","rounded":"","clearable":""},on:{"input":function (value) { return _vm.handleFilter('status', value); }},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('TextInput',{staticClass:"filter__col-4",attrs:{"placeholder":"Почта","dense":"","outlined":"","rounded":"","label":"Почта","append-icon":"$search","clearable":""},on:{"input":function (value) { return _vm.handleFilter('query', value); }},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}}),_c('div',{staticClass:"filter__col-2"},[_c('Parameters',{attrs:{"title":"Действия","list":_vm.menu},on:{"select-option":_vm.handleMenuItemClick}})],1)],1):_vm._e(),_c('VDataTable',{staticClass:"data-table_clickable",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.filter,"server-items-length":_vm.pagination.total,"footer-props":_vm.footerOptions,"hide-default-footer":"","disable-sort":true},on:{"update:options":function($event){_vm.filter=$event},"click:row":_vm.handleRedirectEmployee,"update:items-per-page":function($event){_vm.filter.page = 1}},scopedSlots:_vm._u([{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.city ? _vm.formatCity(item.city.name) : ''))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPhone(item.phone)))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-body-1 teal--text text--darken-4"},[_vm._v(_vm._s(item.email))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('UserSocialCard',{attrs:{"user":item}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('Tag',{attrs:{"type":"bright","userStatuses":item.status.value}},[_vm._v(_vm._s(item.status.name))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}])}),_c('SmartSearchModal',{attrs:{"visible":_vm.showSmartSearchMasters,"noSwitchMethodSearch":true},on:{"update:visible":function($event){_vm.showSmartSearchMasters=$event},"selectUser":_vm.handleRedirectEmployee}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }