

















































import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { SearchType, SmartSearchFilter, SmartSearchRequest } from '@/store/types'
import Select from '@/components/_uikit/controls/Select.vue'
import DictionaryModule from '@/store/modules/dictionary'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import SmartSearchFieldsRow from '@/components/forms/smart-search/SmartSearchFieldsRow.vue'

@Component({
  components: {
    Autocomplete,
    CourseSearch,
    Select,
    SmartSearchFieldsRow,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class SmartSearchFields extends NotifyMixin {
  @Prop({ required: true })
  private data!: SmartSearchRequest

  private filtersInner: SmartSearchFilter[] = []

  private get orderStatusOptions() {
    return DictionaryModule.orderStatuses
  }

  private get cities () {
    return DictionaryModule.cities
  }

  private get isOrderSearch() {
    return this.data.type === SearchType.BY_ORDERS
  }

  private mounted() {
    DictionaryModule.fetchCities()

    if (this.data.filters) {
      this.filtersInner = this.data.filters
    }
  }

  private handleUpdatePhone(phoneString: string): void {
    this.data.phone = phoneString.replace(/^\+7/, '8')
  }

  @Watch('data.filters')
  watchFilters(value: SmartSearchFilter[]) {
    if (value) {
      this.filtersInner = value
    }
  }
}
